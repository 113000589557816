<template>
  <sm-page-loader v-if="isLoadingPage" />
  <sm-permission-error-message v-else-if="!hasPermission" />
  <div v-else class="support-requests-for-current-user">
    <sm-breadcrumbs
      class="support-requests-for-current-user__breadcrumbs"
      :items="breadcrumbs"
    />
    <local-filter
      :fields="filterFields"
      :disabled="!requests.data || !requests.data.length"
      :loading="requests.isLoading"
      @change="onChangeFilter($event)"
    />
    <radio-buttons
      v-model="topTableId"
      :buttons="displayedTabButtons"
      class="table-wrapper__controls"
    >
    </radio-buttons>
    <slot name="top-panel"></slot>
    <sm-datatable
      :headers="table.headers"
      :items="displayedTableRequests"
      :sort="true"
      :sortedType="sort.type"
      :activeOrder="sort.order"
      @sort-by="onChangeSort($event)"
      @change-items-per-page="onChangeLimit($event)"
      class="table-wrapper__table table"
    >
      <template #button="{ row }">
        <button
          @click.prevent="onRedirectToInfo(row.ID)"
          class="table__comments comments"
        >
          Комментарии
        </button>
      </template>
      <template #top-panel>
        <button
          class="editable-list__top-panel-button editable-list__top-panel-button--create"
          @click="onCreate('SupportRequestForCurrentUserCreate')"
        >
          <sm-icon name="plus" />
          Добавить
        </button>
      </template>
    </sm-datatable>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmPermissionErrorMessage from '@/components/common/smPermissionErrorMessage.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import LocalFilter from '@/components/client-sites/LocalFilter.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import RadioButtons from '@/components/client-sites/RadioButtons.vue';
//icons
import SmIcon from '@/components/common/SmIcon.vue';

export default {
  name: 'SupportRequestsForCurrentUser',

  components: {
    SmPageLoader,
    SmPermissionErrorMessage,
    SmBreadcrumbs,
    LocalFilter,
    SmDatatable,
    RadioButtons,
    SmIcon,
  },

  data() {
    return {
      containers: [
        { id: 'top', minHeight: 95 },
        { id: 'center', minHeight: 115 },
        { id: 'bottom', minHeight: 135 },
      ],
      isLoadingPage: false,
      breadcrumbs: [
        {
          text: 'Заявки',
          route: { name: 'RequestsMain' },
        },
        {
          text: 'Мои заявки',
        },
      ],
      topTableTabs: [
        { id: 'atWork', name: 'В работе' },
        { id: 'new', name: 'Новые' },
        { id: 'unanswered', name: 'Ожидание ответа' },
        { id: 'responseWaiting', name: 'Неотвеченные' },
      ],
      topTableId: null,
      filterValue: null,
      sort: {
        order: 'Added',
        type: 'desc',
      },
    };
  },

  computed: {
    ...mapState({
      sid: (state) => state.krisha.sid,
      requests: (state) => state.krisha.requests,
    }),

    hasPermission() {
      if (this.sid.errors) return false;
      return true;
    },

    filterFields() {
      const selectFilterItems = this.selectFilterItems;

      return [
        { id: 'ID', title: 'Номер', type: 'text' },
        {
          id: 'Priority',
          title: 'Приоритет',
          type: 'select',
          items: selectFilterItems.priorities,
        },
        {
          id: 'CusName',
          title: 'Клиент',
          type: 'select',
          items: selectFilterItems.clients,
        },
        {
          id: 'InitiationStatusName',
          title: 'Статус',
          type: 'select',
          items: selectFilterItems.statuses,
        },
        { id: 'Name', title: 'Название', type: 'text' },
        { id: 'Added', title: 'Создан', type: 'date' },
        {
          id: 'LockersNames',
          title: 'Заблокирован',
          type: 'text',
        },
        {
          id: 'LastConsultantMessageDate',
          title: 'Последний комментарий',
          type: 'date',
        },
        {
          id: 'TypeShortName',
          title: 'Тип',
          type: 'select',
          items: selectFilterItems.types,
        },
      ];
    },

    table() {
      return {
        headers: [
          { text: 'Приоритет', order: 'Priority', alias: 'Priority' },
          {
            text: 'Статус',
            order: 'InitiationStatusName',
            alias: 'InitiationStatusName',
          },
          { text: 'Номер', order: 'ID', alias: 'ID' },
          { text: 'Клиент', order: 'CusName', alias: 'CusName' },
          { text: 'Название', order: 'Name', alias: 'Name' },
          { text: 'Создан', order: 'Added', alias: 'Added' },
          {
            text: 'Заблокирован',
            order: 'LockersNames',
            alias: 'LockersNames',
          },
          {
            text: 'Последний комментарий',
            order: 'LastConsultantMessageDate',
            alias: 'LastConsultantMessageDate',
          },
          { text: 'Тип', order: 'TypeShortName', alias: 'TypeShortName' },
          { text: '', order: '', alias: 'button' },
        ],
      };
    },

    displayedTableRequests() {
      const topTableTabs = this.topTableTabs;
      const topTableId = this.topTableId;
      const displayedAtWorkRequests = this.displayedAtWorkRequests;
      const displayedNewRequests = this.displayedNewRequests;
      const displayedUnansweredRequests = this.displayedUnansweredRequests;
      const displayedResponseWaitingRequests =
        this.displayedResponseWaitingRequests;

      if (topTableId === topTableTabs[0].id) {
        return displayedAtWorkRequests;
      }

      if (topTableId === topTableTabs[1].id) {
        return displayedNewRequests;
      }

      if (topTableId === topTableTabs[2].id) {
        return displayedUnansweredRequests;
      }

      return displayedResponseWaitingRequests;
    },

    displayedTabButtons() {
      const topTableTabs = this.topTableTabs;
      const displayedAtWorkRequestsLength = this.displayedAtWorkRequests.length;
      const displayedNewRequestsLength = this.displayedNewRequests.length;
      const displayedUnansweredRequestsLength =
        this.displayedUnansweredRequests.length;
      const displayedResponseWaitingRequestsLength =
        this.displayedResponseWaitingRequests.length;

      let displayedTabButtons = [];

      for (let i = 0; i < topTableTabs.length; i++) {
        const currentTab = topTableTabs[i];

        let currentTabItemsLength = 0;

        if (i === 0) {
          currentTabItemsLength = displayedAtWorkRequestsLength;
        } else if (i === 1) {
          currentTabItemsLength = displayedNewRequestsLength;
        } else if (i === 2) {
          currentTabItemsLength = displayedUnansweredRequestsLength;
        } else {
          currentTabItemsLength = displayedResponseWaitingRequestsLength;
        }

        displayedTabButtons.push({
          ...currentTab,
          name: `${currentTab.name} (${currentTabItemsLength})`,
        });
      }

      return displayedTabButtons;
    },

    displayedAtWorkRequests() {
      const sortedList = this.sortedList;

      return sortedList.filter((request) => {
        return (
          request.IsAnswered && request.IsLockedByCurrentUser && !request.IsWait
        );
      });
    },

    displayedNewRequests() {
      const sortedList = this.sortedList;

      return sortedList.filter((request) => {
        return (
          !request.IsLockedByCurrentUser &&
          !request.IsWait &&
          request.TypeShortName !== 'W'
        );
      });
    },

    displayedUnansweredRequests() {
      const sortedList = this.sortedList;

      return sortedList.filter((request) => {
        return request.IsWait;
      });
    },

    displayedResponseWaitingRequests() {
      const sortedList = this.sortedList;

      return sortedList.filter((request) => {
        return (
          !request.IsAnswered &&
          request.IsLockedByCurrentUser &&
          !request.IsWait
        );
      });
    },

    sortedList() {
      const filteredRequests = this.filteredRequests;
      const sort = this.sort;

      const DATE_FIELDS = ['Added', 'LastConsultantMessageDate'];

      const fieldType = DATE_FIELDS.includes(sort.order) ? 'date' : 'string';

      return this.getSortedList({
        list: filteredRequests,
        fieldType,
        fieldName: sort.order,
        orderType: sort.type,
      });
    },

    filteredRequests() {
      const requests = this.requests.data || [];
      const filterValue = this.filterValue;

      const checkIncludes = (request, field) => {
        return request[field]
          ?.toLowerCase()
          ?.includes(filterValue[field].toLowerCase());
      };
      const checkDates = (request, field) => {
        const requestDate = request[field]?.split(' ')[0];
        const filterDate = filterValue[field];
        return requestDate === filterDate;
      };

      let filteredRequests = requests;

      if (filterValue) {
        filteredRequests = filteredRequests.filter((request) => {
          if (filterValue.Priority && !checkIncludes(request, 'Priority')) {
            return false;
          }

          if (
            filterValue.InitiationStatusName &&
            !checkIncludes(request, 'InitiationStatusName')
          ) {
            return false;
          }

          if (filterValue.CusName && !checkIncludes(request, 'CusName')) {
            return false;
          }

          if (filterValue.Name && !checkIncludes(request, 'Name')) {
            return false;
          }

          if (filterValue.ID) {
            return +request.ID === +filterValue.ID;
          }

          if (filterValue.Added && !checkDates(request, 'Added')) {
            return false;
          }

          if (
            filterValue.LockersNames &&
            !checkIncludes(request, 'LockersNames')
          ) {
            return false;
          }

          if (
            filterValue.LastConsultantMessageDate &&
            !checkDates(request, 'LastConsultantMessageDate')
          ) {
            return false;
          }

          if (
            filterValue.TypeShortName &&
            !checkIncludes(request, 'TypeShortName')
          ) {
            return false;
          }

          return true;
        });
      }

      return filteredRequests;
    },

    selectFilterElements() {
      const requests = this.requests.data || [];

      const filterElements = {
        priorities: new Set(),
        statuses: new Set(),
        clients: new Set(),
        types: new Set(),
      };

      for (let i = 0; i < requests.length; i++) {
        const currentContract = requests[i];

        if (currentContract.Priority) {
          filterElements.priorities.add(currentContract.Priority);
        }
        if (currentContract.InitiationStatusName) {
          filterElements.statuses.add(currentContract.InitiationStatusName);
        }
        if (currentContract.CusName) {
          filterElements.clients.add(currentContract.CusName);
        }
        if (currentContract.TypeShortName) {
          filterElements.types.add(currentContract.TypeShortName);
        }
      }

      filterElements.priorities = [...filterElements.priorities];
      filterElements.statuses = [...filterElements.statuses];
      filterElements.clients = [...filterElements.clients];
      filterElements.types = [...filterElements.types];

      return filterElements;
    },

    selectFilterItems() {
      const selectFilterElements = this.selectFilterElements;

      const filterItems = {
        priorities: selectFilterElements.priorities.map((priority) => {
          return { name: priority, value: priority };
        }),
        statuses: selectFilterElements.statuses.map((status) => {
          return { name: status, value: status };
        }),
        clients: selectFilterElements.clients.map((client) => {
          return { name: client, value: client };
        }),
        types: selectFilterElements.types.map((type) => {
          return { name: type, value: type };
        }),
      };

      return filterItems;
    },
  },

  created() {
    this.isLoadingPage = true;

    this.auth()
      .then(() => {
        if (this.requests.data?.length) return;

        this.getRequestList();
      })
      .finally(() => {
        this.isLoadingPage = false;
      });
  },

  methods: {
    ...mapActions({
      auth: 'krisha/auth',
      getRequestList: 'krisha/getRequestList',
      clearState: 'krisha/clearState',
    }),

    onChangeFilter(filterValue) {
      this.filterValue = filterValue;
    },

    onChangeSort({ order, type }) {
      if (!order) return;

      this.$set(this.sort, 'order', order);
      this.$set(this.sort, 'type', type);
    },

    onRedirectToInfo(id) {
      const routeData = this.$router.resolve({
        name: 'SupportRequestInfo',
        params: { id },
      });

      window.open(routeData.href, '_blank');
    },

    getSortedList({ list, fieldType, fieldName, orderType }) {
      const listCopy = [...list];

      if (fieldType === 'date') {
        if (orderType === 'desc') {
          return listCopy.sort((a, b) => {
            const momentA = this.$moment.parseZone(a[fieldName], 'DD.MM.YYYY');
            const momentB = this.$moment.parseZone(b[fieldName], 'DD.MM.YYYY');

            if (momentA.isBefore(momentB)) return 1;
            if (momentA.isAfter(momentB)) return -1;
            return 0;
          });
        }

        return listCopy.sort((a, b) => {
          const momentA = this.$moment.parseZone(a[fieldName], 'DD.MM.YYYY');
          const momentB = this.$moment.parseZone(b[fieldName], 'DD.MM.YYYY');

          if (momentA.isBefore(momentB)) return -1;
          if (momentA.isAfter(momentB)) return 1;
          return 0;
        });
      }

      if (orderType === 'desc') {
        return listCopy.sort((a, b) => {
          if (a[fieldName] < b[fieldName]) return 1;
          if (a[fieldName] > b[fieldName]) return -1;
          return 0;
        });
      }

      return listCopy.sort((a, b) => {
        if (a[fieldName] > b[fieldName]) return 1;
        if (a[fieldName] < b[fieldName]) return -1;
        return 0;
      });
    },
    onCreate() {
      this.$router.push({ name: 'SupportRequestForCurrentUserCreate' });
    },
  },
};
</script>

<style lang="scss">
.support-requests-for-current-user {
  & .resizable-containers {
    height: 78vh;
  }

  & .container {
    padding: 20px 0;

    &--top {
      padding-top: 0;
    }

    &--bottom {
      padding-bottom: 0;
    }
  }

  & .table-wrapper {
    height: 100%;
    padding: 0 10px;
  }

  & .container {
    &--bottom {
      padding-bottom: 20px;
    }
  }

  & .table-wrapper__description {
    margin-bottom: 10px;
  }

  & .description {
    font-weight: 500;
  }

  & .table-wrapper__controls {
    margin-bottom: 10px;
  }

  & .table {
    max-height: calc(100% - 31px);
    max-width: 100%;
    overflow: auto;
    box-shadow: 0px 8px 8px 2px var(--light-gray);
  }

  & .comments {
    border-bottom: 1px dashed var(--blue);
    color: var(--blue);
  }

  & .datatable__inner-wrapper {
    margin: 0;
  }

  & .datatable {
    margin: 0;

    @media (max-width: 1310px) {
      min-width: 1100px;
    }
  }

  & .datatable__cell {
    &--Priority,
    &--InitiationStatusName,
    &--Added,
    &--LastConsultantMessageDate {
      min-width: 100px;
    }

    &--ID,
    &--TypeShortName {
      min-width: 80px;
    }

    &--CusName,
    &--Name,
    &--LockersNames {
      min-width: 120px;
    }

    &--button {
      min-width: 130px;
    }
  }
}

.support-requests-for-current-user__breadcrumbs {
  margin-bottom: 20px;
}
.editable-list__top-panel-button--create {
  &:hover {
    color: var(--blue);
  }
}
.editable-list__top-panel-button {
  display: flex;
  align-items: center;

  font-weight: 500;
  line-height: 1;
  color: var(--gray);

  margin: 0;
  border: none;
  background: transparent;
}
</style>
